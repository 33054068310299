@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Kanit:wght@500&family=Prompt&family=Secular+One&display=swap);



button{
  transition: 1s;
  cursor: pointer;
}

.cart-button{
  position: relative;
  font-size: calc(10px + 1vh + 1vw);
  color: #093854;
  padding: 8px;
  transition: .5s;
}

.cart-button::before{
  content: "";
  position: absolute;
  background-color: #093854;
  height: 3px;
  left: 0;
  transition: .5s;
  top: -5px;
  width: 0;
}

.cart-button:hover::before{
  width: 100%;
}

.cart-button::after{
  content: "";
  position: absolute;
  background-color: #093854;
  height: 3px;
  left: 0;
  transition: .5s;
  bottom: -5px;
  width: 0;
}

.cart-button:hover::after{
  width: 100%;
}

.cart-btn{
  transition: 1s;
}
.cart-btn:hover{
  background: #dafdfc2c;
}

.styled-button.DarkBack{
  color: #DAFDFC;
  border: 3px solid #DAFDFC;
}
.styled-button.DarkBack:hover{
  background: #DAFDFC;
  color: #01091f;
  border-color: #DAFDFC;
}
@-webkit-keyframes ButtonAn{
  50%{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@keyframes ButtonAn{
  50%{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

.home-container.green-ocean section{
  background: rgba(0, 0, 30, .6);
}
.home-container h1{
  font-size: calc(2vw + 2vh + 1rem);
}
/* .home-container a{
  font-size: calc(1vw + 1vh + 1rem);
  border: 3px solid;
  background-image:  linear-gradient(to left, #01091f, #0c3255);
  color: #DAFDFC;
  cursor: pointer;
  transition: 1s;
  padding: 5px 10px;
  border-radius: 5px;
}
.home-container a:first-child{
  background: transparent;
  color: #01091f;
}
.home-container a:hover{
  transition: 1s;
  background-image: linear-gradient(to left, #0c3255, #0c3255);
}
.home-container a:first-child:hover{
  background: #01091f;
  color: #DAFDFC;
} */

.fa-whatsapp{ color: #DAFDFC;}

.Special-Span{
  position: relative;
}
.Special-Span::after{
  content: "";
  position: absolute;
  background-color: #c2fffd;
  height: 5px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.product-main-img{
  width: 100%;
  height: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
